"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rangeInitial = void 0;
const rangeInitial = function (value, def, min, max, step = 1) {
    let v;
    if (typeof value === 'number') {
        v = Math.min(max, Math.max(min, value));
    }
    else {
        v = Math.min(max, Math.max(min, def));
    }
    if ((v % step) != 0) {
        if ((max - v) < (v - min)) {
            while ((v % step) != 0) {
                v += 1;
            }
        }
        else {
            while ((v % step) != 0) {
                value -= 1;
            }
        }
    }
    return v;
};
exports.rangeInitial = rangeInitial;
